
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import GlobalMixin from '@/mixins/global'

@Component({
  name: 'passportCombineAccount',
  components: {
  },
  mixins: [GlobalMixin]
})
export default class PassportCombineAccountPage extends Vue {
  @Provide() public account: any = []
  @Provide() public prompt: any = []
  @Provide() public sysNo: any = []
  @Provide() public allSysNo: any = []
  @Provide() public subSysNo: any = []
  @Provide() public userName: any = []
  @Provide() public userTips: any = []
  @Provide() public dialog: boolean = false
  @Provide() public accountDialog: boolean = false

  public created () {
    this.refresh()
    this.promptDialog()
  }

  @Emit()
  public async promptDialog () {
    try {
      const res = await httpHelper.get({
        url: 'Account/MergeIntroduce'
      })
      this.prompt = res.value
    } catch (err) {
    }
  }

  @Emit()
  public async refresh () {
    try {
      const res = await httpHelper.get({
        url: 'Account/Merge'
      })
      this.account = res.value
      this.allSysNo = res.value.map((item:any) => item.customerSysNo)
    } catch (err) {
    }
  }

  @Emit()
  public selectAccount (list:any) {
    this.sysNo = list.customerSysNo
    this.userName = list.nickName
    this.userTips = list.tips
    this.accountDialog = true
  }

  @Emit()
  public async mergeAccount (sysNo:number) {
    try {
      // const url = encodeURI(encodeURI(`https://m.benlai.com/userHome/`))
      const subSysNo = this.allSysNo.slice().filter((item: number) => item !== sysNo)
      const res = await httpHelper.post({
        url: 'Account/Merge',
        data: {
          masterSysNo: sysNo,
          slaveSysNo: subSysNo[0]
        }
      })
      this.$toasted.show('关联成功')
      const source = sessionStorage.getItem('source') || ''
      const storeCode = sessionStorage.getItem('storeCode') || ''
      let jumpUrl = ''
      const urlRoot = window.location.href.indexOf('pre-') > -1 ? 'https://pre-mc.benlai.com' : 'https://mc.benlai.com'
      jumpUrl = `${urlRoot}/user/index`
      if (source === '11') {
        jumpUrl = 'https://h5.benlai.com/me'
      }
      if (source === '15') { // 如果是从life商城触发的合并账号，跳回到来福商城
        let code = ''
        if (storeCode) {
          code = `/${storeCode}/me`
        } else {
          code = `/storeSwitch`
        }
        jumpUrl = `https://life.benlai.com${code}`
      }
      location.replace(jumpUrl)
    } catch (err) {
      this.$toasted.show(err.msg)
    }
  }

  @Emit()
  public showDialog () {
    this.dialog = true
  }

  @Emit()
  public cancelBack () {
    this.dialog = false
  }

  @Emit()
  public cancelAccount () {
    this.accountDialog = false
  }

  @Emit()
  private goBack () {
    window.history.back()
  }
}
